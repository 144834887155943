<template>
  <div class="navBar">
    <div class="nav_content">
      <div class="navLeft">
        <img src="@/assets/navLogo.png"
          alt="">
        <span class="name">TOOERP-巧数科技</span>
      </div>
      <div class="navRight">
        <div class="navRouters">
          <div v-for="(item,index) in navRouters"
            :key="index"
            @mouseover="setHoverName(item.name)"
            @mouseout="hoverName = ''"
            :class="getClass(item)"
            @click="toPage(item)">
            {{ item.title }}
            <div class="theSelect"
              v-if="item.name == 'product'"
              :class="hoverName === item.name && 'theSelectActive'">
              <div>
                <div v-for="(child,childIndex) in item.child"
                  :key="childIndex"
                  class="theSelectItem"
                  :class="getClass(child)"
                  @click.stop="toPage(child)">
                  <img :src="child.icon">
                  {{ child.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="loginButton hoverBtn"
          @click="show = true">登录系统</div>
      </div>
      <div class="menu">
        <div class="btnBox"
          @click="show = true">登录系统</div>
        <i class="iconfont icon-caidan_o"
          @click="phoneMenuShow = !phoneMenuShow"></i>
      </div>
    </div>
    <tryUseDialog :show="show"
      @close="show = false"
      type="login"></tryUseDialog>

    <div class="phoneMenu"
      :class="phoneMenuShow ? 'menuShow':'menuClose'"
      @click="phoneMenuShow = false"></div>
    <div class="menuContent"
      :class="phoneMenuShow ? 'open':'close'">
      <div class="closeBox">
        <i class="iconfont icon-guanbi"
          @click="phoneMenuShow = false"></i>
      </div>

      <div v-for="(item,index) in navRouters"
        :key="index"
        :class="item.child ? '':'routerItem'"
        @click="toPage(item)">
        <div :class="item.child ? 'routerTitle':'' ">
          <span :class="ruoterName === item.name && 'textActive'">{{item.title}}</span>
        </div>
        <ul v-if="item.child">
          <li v-for="(childPath,childIndex) in item.child"
            :key="childIndex"
            class="routerItem"
            @click.stop="toPage(childPath)"
            :class="ruoterName === childPath.name && 'textActive'">{{(childPath.title)}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import tryUseDialog from '@/components/tryUseDialog'
export default {
  data() {
    return {
      navRouters: [
        {
          title: '首页',
          path: '/',
          name: 'index'
        },
        {
          title: '产品介绍',
          path: '/retail',
          name: 'product',
          child: [
            {
              title: '批发零售系统',
              icon: require('../assets/navProduct1.png'),
              path: '/retail',
              name: 'retail'
            },
            // {
            //   title: 'PTU2商城',
            //   icon: require('../assets/navProduct2.png'),
            //   path: '/ptuStore',
            //   name: 'ptuStore'
            // },
            // {
            //   title: '批发连锁',
            //   icon: require('../assets/navProduct3.png'),
            //   path: '/wholesale',
            //   name: 'wholesale'
            // },
            {
              title: '批兔兔点货系统',
              icon: require('../assets/navProduct4.png'),
              path: '/ptuapp',
              name: 'ptuapp'
            },
            {
              title: '餐馆收银点餐系统',
              icon: require('../assets/navProduct5.png'),
              path: '/restaurant',
              name: 'restaurant'
            }
          ]
        },
        {
          title: 'APP下载',
          path: '/appDownload',
          name: 'appDownload'
        },
        {
          title: '服务支持',
          path: '/service',
          name: 'service'
        },
        {
          title: '关于我们',
          path: '/aboutUs',
          name: 'aboutUs'
        }
      ],
      hoverName: '',
      show: false,
      // 手机端展示菜单
      phoneMenuShow: false
    }
  },
  components: {
    tryUseDialog
  },
  computed: {
    ruoterName() {
      return this.$route.name
    }
  },
  created() {
    console.log(this.$route)
  },
  mounted() {},
  methods: {
    setHoverName(name) {
      this.hoverName = name
    },
    getClass(item) {
      if (item.name === this.ruoterName) {
        return 'active'
      } else if (item.name === 'product') {
        if (
          this.ruoterName == 'retail' ||
          this.ruoterName == 'ptuStore' ||
          this.ruoterName == 'wholesale' ||
          this.ruoterName == 'ptuapp' ||
          this.ruoterName == 'restaurant'
        ) {
          return 'active'
        }
      }
    },
    toPage(item) {
      this.$router.push(item.path)
      this.phoneMenuShow = false
    }
  }
}
</script>

<style scoped lang="scss">
.navBar {
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px #d8e3f2;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.nav_content {
  width: 90%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .navLeft {
    display: flex;
    align-items: center;
    height: 100%;
    img {
      width: 50px;
      height: 50px;
      margin-right: 20px;
      border-radius: 10px;
      box-shadow: 0px 2px 10px 0px #d8e3f2;
    }
    span {
      font-size: 18px;
      font-weight: 600;
      color: #092e53;
      line-height: 25px;
    }
  }
  .navRight {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    .navRouters {
      flex-grow: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 67px;
      > div {
        position: relative;
        height: 100%;
        line-height: 80px;
        cursor: pointer;
        &:hover {
          border-bottom: 4px #2e93fe solid;
          color: #2e93fe;
          font-weight: 800;
        }
      }
      > .active {
        border-bottom: 4px #2e93fe solid;
        color: #2e93fe;
        font-weight: 800;
      }
    }
    .loginButton {
      width: 130px;
      height: 40px;
      background: #2e93fe;
      border-radius: 20px;
      font-size: 16px;
      // font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 22px;
      text-align: center;
      line-height: 40px;
    }
  }
}

.theSelect {
  position: absolute;
  width: 240px;
  height: 0;
  overflow: hidden;
  padding: 0 10px;
  > div {
    color: #000;
    margin-top: 9px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px #d8e3f2;
    border-radius: 8px;
    height: 200px;
    width: 210px;
    padding: 16px 0;
    // display: flex;
    // align-items: center;
    // flex-wrap: wrap;
    .theSelectItem {
      width: 210px;
      height: 60px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      img {
        width: 34px;
        height: 34px;
        margin-right: 10px;
      }
      &:nth-child(2n) {
        // margin-left: 12px;
      }
      &:hover {
        background: #eaf5ff;
      }
    }
    .active {
      background: #eaf5ff;
    }
  }
}
.theSelectActive {
  height: 200px !important;
  transition: height 0.3s;
}
// pc端隐藏
.menu {
  display: none;
}
.menuContent {
  display: none;
}
@media screen and (max-width: 768px) {
  .navBar {
    height: 6rem;
  }
  .nav_content {
    width: 100%;
    align-items: center;
    .navLeft {
      img {
        width: 5.6rem;
        height: 5.6rem;
      }
      span {
        font-size: 1.4rem;
        font-weight: 600;
        color: #092e53;
        line-height: 25px;
      }
    }
    .navRight {
      display: none;
    }
    .menu {
      display: flex;
      align-items: center;
      .btnBox {
        width: 6rem;
        height: 2.4rem;
        background: #2e93fe;
        border-radius: 4px;
        text-align: center;
        line-height: 2.4rem;
        color: white;
        margin-right: 12px;
      }
      i {
        font-size: 3.6rem;
      }
    }
  }
  .phoneMenu {
    position: fixed;
    right: 0;
    width: 100%;
    height: 200vh;
    background: rgba(0, 0, 0, 0.5);
  }
  .menuShow {
    display: block;
  }
  .menuClose {
    display: none;
  }
  .menuContent {
    box-sizing: border-box;
    display: block;
    color: black;
    position: fixed;
    right: 0;
    top: 0;

    .closeBox {
      padding: 0 1rem;
      font-size: 3rem;
      height: 6.5rem;
      line-height: 6.5rem;
      text-align: right;
    }
    .routerTitle {
      height: 5rem;
      line-height: 5rem;
      font-size: 1.8rem;
      font-weight: bold;
    }
    .routerItem {
      height: 5rem;
      line-height: 5rem;
      font-size: 1.8rem;
    }
    ul {
      margin-left: 1.6rem;
    }
    li {
      list-style: none;
    }
  }
  .open {
    background: white;
    height: 100%;
    width: 60%;
    transition: width 0.3s;
    padding-left: 1.4rem;
  }
  .close {
    background: white;
    height: 100%;
    width: 0;
    transition: width 0.3s;
  }

  .textActive {
    color: #3eb5ff;
  }
}
</style>
